import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SellCarousel from "../SellCarousel/SellCarousel";
import './Home.css'
import { useCart } from '../../context/CartContext';
import GeneralService from "../../services/general.service";
import Footer from "../Includes/Footer";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import secureLocalStorage from "react-secure-storage";
import UpdatedHeader from "../Includes/UpdatedHeader";
import CustomModal from "../Includes/CustomModal";
import Slider from "react-slick/lib/slider";

export default function Home() {
  const [sliders, setSliders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [vegetables, setVegetables] = useState([]);
  const [fruits, setFruits] = useState([]);
  const [dryFruits, setDryFruits] = useState([]);
  const [snacks, setSnacks] = useState([]);
  const [spices, setSpices] = useState([]);
  const [peeledVgs, setPeeledVgs] = useState([]);
  const [meat, setMeat] = useState([]);
  const [milk, setMilk] = useState([]);
  const [aata, setAata] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const [cartCounter, setCartCounter] = useState(0);
  const { updateCartCounter, updateUserAddress, userInfo, addItemToCart, removeItemFromCart, decreaseQty, cartItems } = useCart();
  const loggedInId = userInfo.id;
  const [products, setProducts] = useState([]);
  const [borderColorIndex, setBorderColorIndex] = useState(0);

  const [modalVisible, setModalVisible] = useState(false);
  const [notificationData, setNotificationData] = useState({
    id: null,
    heading: '',
    body: '',
    body2: '',
    button: ''
  });

  useEffect(() => {
    fetchNotification();
  }, []);

  const handlePressGotIt = () => {
    setModalVisible(false);
    const notificationId = notificationData.id;
    localStorage.setItem('storedNotificationId', notificationId.toString()); // Store the new notification ID
  };

  const fetchNotification = async () => {
    try {
      const response = await GeneralService.getNotification();
      const { data } = response;
      const { response: res } = data;
      const { id, body, body2, heading, button } = res;

      const storedId = localStorage.getItem('storedNotificationId');

      if (id != storedId) {
        setNotificationData({ id, body, body2, heading, button });
        setModalVisible(true);
        localStorage.setItem('storedNotificationId', id);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getQuantityInCart = (productId) => {
    // console.log(`id=${productId}`);
    const item = cartItems.find(i => i.id === productId);
    return item ? item.quantity : 0;
  };
  const decreaseQuantity = (catId, prodId, product) => {
    const decreaseQnty = async () => {
      try {
        if (catId == 1) {
          const updatedProducts = fruits.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) - 1
              };
            }
            return product;
          });
          setFruits(updatedProducts);
        } else if (catId == 2) {
          const updatedProducts = vegetables.map(product => {
            console.log(product);
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) - 1
              };
            }
            return product;
          });
          setVegetables(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 8) {
          const updatedProducts = dryFruits.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) - 1
              };
            }
            return product;
          });
          setDryFruits(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 9) {
          const updatedProducts = snacks.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) - 1
              };
            }
            return product;
          });
          setSnacks(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 13) {
          const updatedProducts = spices.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) - 1
              };
            }
            return product;
          });
          setSpices(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 12) {
          const updatedProducts = peeledVgs.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) - 1
              };
            }
            return product;
          });
          setPeeledVgs(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 14) {
          const updatedProducts = meat.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) - 1
              };
            }
            return product;
          });
          setMeat(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 15) {
          const updatedProducts = milk.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) - 1
              };
            }
            return product;
          });
          setMilk(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 16) {
          const updatedProducts = aata.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) - 1
              };
            }
            return product;
          });
          setAata(updatedProducts);
          console.log(updatedProducts);
        }

        decreaseQty(product);
      } catch (err) {
        console.log(err);
      }
    }
    // getCartCounter();
    decreaseQnty();
  };

  const getCartCounter = async () => {
    try {
      let userId = secureLocalStorage.getItem("id");
      console.log(`user-id=${userId}`);
      const cartResponse = await GeneralService.cartCounterByUserId(userId);
      const { data: cartData } = cartResponse;
      // console.log(`home-data=${cartData}`);
      const { counter: cartNo, address } = cartData;
      // console.log(`counter=${cartNo}`);
      setCartCounter(cartNo);
      updateCartCounter(cartNo);
      updateUserAddress(address);
    } catch (err) {
      console.log(err);
      setCartCounter(0);
    }
  }


  const addCart = async (catId, prodId, product) => {
    // if (!loggedInId) {
    //   navigate("/sign-in");
    // }

    // alert(JSON.stringify(product));
    let allowdStock = product.stock_available;
    let allowdQty = product.max_qty;
    let addedQty = getQuantityInCart(prodId);

    const increaseQnty = async () => {
      try {
        if (catId == 1) {
          const updatedProducts = fruits.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) + 1
              };
            }
            return product;
          });
          setFruits(updatedProducts);
        } else if (catId == 2) {
          const updatedProducts = vegetables.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) + 1
              };
            }
            return product;
          });
          setVegetables(updatedProducts);
          // console.log(updatedProducts);
        } else if (catId == 8) {
          const updatedProducts = dryFruits.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) + 1
              };
            }
            return product;
          });
          setDryFruits(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 9) {
          const updatedProducts = snacks.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) + 1
              };
            }
            return product;
          });
          setSnacks(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 13) {
          const updatedProducts = spices.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) + 1
              };
            }
            return product;
          });
          setSpices(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 12) {
          const updatedProducts = peeledVgs.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) + 1
              };
            }
            return product;
          });
          setPeeledVgs(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 14) {
          const updatedProducts = meat.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) + 1
              };
            }
            return product;
          });
          setMeat(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 15) {
          const updatedProducts = milk.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) + 1
              };
            }
            return product;
          });
          setMilk(updatedProducts);
          console.log(updatedProducts);
        } else if (catId == 16) {
          const updatedProducts = aata.map(product => {
            if (product.id === prodId) {
              return {
                ...product,
                quantity_added: parseInt(product.quantity_added || 0) + 1
              };
            }
            return product;
          });
          setAata(updatedProducts);
          console.log(updatedProducts);
        }

        addItemToCart(product);
      } catch (err) {
        console.log(err);
      }
    }
    console.log(`${parseInt(allowdStock)}`);
    // getCartCounter();
    if (parseInt(addedQty) < parseInt(allowdStock)) {
      if (parseInt(addedQty) < parseInt(allowdQty)) {
        increaseQnty();
      }
    }
  }

  const fetchSliders = async () => {
    try {
      const response = await GeneralService.getSliders();
      const { data } = response;
      const { response: res } = data;
      console.log(res.map(item => item.image));
      setSliders(res.map(item => item.image));
      // setSliders(["restaurant6.png"]);
      // const url = "https://api.veggieking.pk/public/upload/";
      // setSliders(res.map(item => ({ source: { uri: url + item.image } })));

    } catch (err) {
      console.log(err);
    }
  }

  const fetchProductsByType = async (catId) => {
    try {
      // TODO
      let userId = secureLocalStorage.getItem("id");
      const timeout = 8000;
      const response = await Promise.race([
        GeneralService.listLimProductByCatCart(catId, userId),
        new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), timeout))
      ]);
      console.log(response.data.response);
      if (response) {
        if (catId == 2) {
          setVegetables(response.data.response);
        } else if (catId == 1) {
          setFruits(response.data.response);
        } else if (catId == 8) {
          setDryFruits(response.data.response);
        } else if (catId == 9) {
          setSnacks(response.data.response);
        } else if (catId == 13) {
          setSpices(response.data.response);
        } else if (catId == 12) {
          setPeeledVgs(response.data.response);
        } else if (catId == 14) {
          setMeat(response.data.response);
        } else if (catId == 15) {
          setMilk(response.data.response);
        } else if (catId == 16) {
          setAata(response.data.response);
        }
      } else {
        throw new Error('No response from the server');
      }

      // setFeatureLoading(false);
      // console.error('More products fetched');
    } catch (error) {
      console.log(error);
      // setFeatureLoading(false);
      // setVegetables([]);
    }
  };

  const VerticalProducts = ({ id, data, title }) => {
    const [selectedCategory, setSelectedCategory] = useState("All");

    const filterProducts = (category) => {
      if (category === "All") return data;
      return data.filter(product => product.category === category);
    };

    const filteredProducts = filterProducts(selectedCategory);

    return (
      <>
        {/* Display Filtered Products */}
        {filteredProducts.length > 0 && (
          <div>
            {/* Title and See All Link */}
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h4
                className="fw-bold mb-0"
                style={{
                  color: "#f44c00",
                  fontSize: "26px",
                  textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                }}
              >
                {title}
              </h4>
              <Link
                className="see-all-link d-flex align-items-center text-decoration-none"
                to="/category-products"
                state={{ id: id, name: title }}
                style={{
                  color: "#f44c00",
                  fontSize: "18px",
                  fontWeight: "bold",
                  transition: "color 0.3s ease",
                }}
              >
                See All
                <i
                  className="bi bi-arrow-right ms-2"
                  style={{ transition: "transform 0.3s ease" }}
                ></i>
              </Link>
            </div>

            {/* Products Grid */}
            <div className="row g-3 mb-5">
              {filteredProducts.map((product) => (
                <div key={product.id} className="col-6 col-sm-4 col-md-3">
                  <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                    <Link
                      to="/product-detail"
                      state={product}
                      className="text-decoration-none link-dark"
                    >
                      <div className="position-relative">
                        <div className="product-back">
                          <img
                            src={`https://api.veggieking.pk/public/upload/${product.image}`}
                            alt=""
                            className="img-fluid rounded-top"
                          />
                        </div>
                      </div>
                    </Link>
                    <div className="card-body">
                      <div className="h5 fw-bold m-0 p-name">
                        {product.name.split(" - ")[0]}
                        <br />
                        <span style={{ fontWeight: "normal" }}>
                          {product.name.split(" - ")[1]}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center text-muted fw-normal add-footer">
                        {/* Price and Stock Information */}
                        <div
                          className={`h5 fw-bold m-0 mt-3 mt-custom ${product.stock_available > 0 ? "" : "error"
                            }`}
                        >
                          {product.stock_available > 0 ? (
                            `Rs. ${product.price}`
                          ) : (
                            <span className="out-of-stock-badge">
                              Out of Stock
                            </span>
                          )}
                        </div>

                        {/* Add to Cart/Quantity Controls */}
                        {product.stock_available > 0 && (
                          <div className="cart-controls d-flex align-items-center">
                            {getQuantityInCart(product.id) > 0 ? (
                              <>
                                <div
                                  className="minus-btn"
                                  onClick={() => {
                                    decreaseQuantity(id, product.id, product);
                                  }}
                                >
                                  <i className="bi bi-dash-lg"></i>
                                </div>
                                <div className="quantity">
                                  {getQuantityInCart(product.id)}
                                </div>
                              </>
                            ) : null}
                            <div
                              className="add-btn"
                              onClick={() => {
                                addCart(id, product.id, product);
                              }}
                            >
                              <i className="bi bi-plus-lg"></i>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  const navigate = useNavigate();
  const userType = secureLocalStorage.getItem("user_type");
  useEffect(() => {
    if (userType == 'rider') {
      navigate("/rider-orders");
    }
  }, [userType]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GeneralService.listAllCategories();
        if (response && response.data.response && Array.isArray(response.data.response)) {
          setCategories(response.data.response);
        } else {
          console.error('API response is not an array:', response);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    // getCartCounter();
    fetchSliders();
    fetchData();
    fetchProductsByType(2);
    fetchProductsByType(1);
    fetchProductsByType(8);
    fetchProductsByType(9);
    fetchProductsByType(13);
    fetchProductsByType(12);
    fetchProductsByType(14);
    fetchProductsByType(15);
    fetchProductsByType(16);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GeneralService.listAllProducts();
        if (response && response.data.response && Array.isArray(response.data.response)) {
          setProducts(response.data.response);
        } else {
          console.error('API response is not an array:', response);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // const borderColors = ['#FF5733', '#33FF57', '#5733FF', '#FFFF33']; 
  const borderColors = ["#00ff00", "#32cd32", "#81c408"];

  const getNextBorderColorIndex = () => {
    // Increment the index and loop back to the beginning if it exceeds the length of the array
    setBorderColorIndex((prevIndex) => (prevIndex + 1) % borderColors.length);
  };

  const arrowStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#81c408",
    color: "white",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    position: "absolute",
    zIndex: 1,
    top: "45%",
    transform: "translateY(-50%)",
  };

  // const CustomNextArrow = (props) => {
  //   const { onClick } = props;
  //   return (
  //     <div
  //       className="slick-next"
  //       onClick={onClick}
  //       style={{ ...arrowStyle, right: "10px" }} 
  //     >
  //       &gt; 
  //     </div>
  //   );
  // };

  // const CustomPrevArrow = (props) => {
  //   const { onClick } = props;
  //   return (
  //     <div
  //       className="slick-prev"
  //       onClick={onClick}
  //       style={{ ...arrowStyle, left: "10px" }} 
  //     >
  //       &lt; 
  //     </div>
  //   );
  // };

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768, // For tablets
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For mobile screens
        settings: {
          slidesToShow: 3, // Adjusted for 3 slides on smaller screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  const firstHalf = categories.slice(0, Math.ceil(categories.length / 2));
  const secondHalf = categories.slice(Math.ceil(categories.length / 2));

  return (
    <>
      {/* <Header /> */}
      <UpdatedHeader />
      <div style={{ marginTop: "1%" }} className="mb-4 bg-white rounded-4 shadow p-1 brands-list home-minus">

        <div>
          {loading ? (
            <div className="row">
              {Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="col-4 px-1">
                  <Skeleton className="card border-0 bg-white" height={150} />
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className="categories-section">
                <h3 className="fw-bold mb-3 category-title" style={{
                  fontSize: "28px", fontWeight: "700", textShadow: "rgba(0, 0, 0, 0.2) 1px 1px 2px",
                  color: "rgb(244, 76, 0)",
                  textAlign: "left",
                  paddingLeft: "10px"
                }}>Categories</h3>

                {/* First Slider */}
                <div className="carousel-wrapper">
                  <Slider {...settings}>
                    {firstHalf.map((row) => (
                      <div key={row.id} className="px-2">
                        <Link
                          to="/category-products"
                          state={{ id: row.id, name: row.name }}
                          className="text-decoration-none link-dark"
                        >
                          <div className="card category-card shadow-sm">
                            <div className="image-container mx-auto">
                              <img
                                src={`https://api.veggieking.pk/public/upload/${row.image}`}
                                alt={row.name}
                                className="hover-zoom"
                              />
                            </div>
                            <h6 className="category-name">{row.name}</h6>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </Slider>
                </div>

                {/* Second Slider */}
                <div className="carousel-wrapper">
                  <Slider {...settings}>
                    {secondHalf.map((row) => (
                      <div key={row.id} className="px-2">
                        <Link
                          to="/category-products"
                          state={{ id: row.id, name: row.name }}
                          className="text-decoration-none link-dark"
                        >
                          <div className="card category-card shadow-sm">
                            <div className="image-container mx-auto">
                              <img
                                src={`https://api.veggieking.pk/public/upload/${row.image}`}
                                alt={row.name}
                                className="hover-zoom"
                              />
                            </div>
                            <h6 className="category-name">{row.name}</h6>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </>
          )}
        </div>

        <VerticalProducts id="2" data={vegetables} title="Vegetables" />
        <VerticalProducts id="1" data={fruits} title="Fruits" />
        <VerticalProducts id="8" data={dryFruits} title="Dry Fruits" />
        <VerticalProducts id="9" data={snacks} title="Snacks" />
        <VerticalProducts id="13" data={spices} title="Spices" />
        <VerticalProducts id="12" data={peeledVgs} title="Peeled & Chopped" />
        <VerticalProducts id="14" data={meat} title="Meat" />
        <VerticalProducts id="15" data={milk} title="Milk" />
        <VerticalProducts id="16" data={aata} title="Desi Chakki Aata" />
      </div>
      <div className="pt-4 pb-5"></div>
      <Footer />
      {/* Add the CustomModal component */}
      <div>
        <CustomModal
          heading={notificationData.heading}
          body={notificationData.body}
          body2={notificationData.body2}
          button={notificationData.button}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          onPressGotIt={handlePressGotIt}
        />
      </div>
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content rounded-4 border-0">
            <div className="modal-header p-4 border-0">
              <h4 className="modal-title" id="staticBackdropLabel">
                Add Your Location
              </h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body pt-0 px-4 pb-4">
              <form>
                <div className="input-group border rounded-3 overflow-hidden mb-3">
                  <span className="input-group-text bg-white border-0 px-3 py-2">
                    <i className="bi bi-search"></i>
                  </span>
                  <input type="text" className="shadow-none form-control bg-white border-0 ps-0"
                    placeholder="Enter Location" />
                </div>
              </form>
              <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3 mb-3 px-0">
                <div>
                  <i className="fa-solid fa-location-crosshairs text-danger"></i>
                </div>
                <div>Use Current Location</div>
              </div>
              <div className="text-muted text-uppercase small mb-1 pt-4">
                Search Results
              </div>
              <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3">
                <span>
                  <i className="bi bi-search h6"></i>
                </span>
                <div className="lh-sm border-bottom py-3 w-100">
                  <h6 className="mb-1">Bangalore</h6>
                  <p className="text-muted m-0">Karnataka, India</p>
                </div>
              </div>
              <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3">
                <span>
                  <i className="bi bi-search h6"></i>
                </span>
                <div className="lh-sm border-bottom py-3 w-100">
                  <h6 className="mb-1">Bangalore internaltional airport</h6>
                  <p className="text-muted m-0">
                    Karmpegowda.in't Airport, Hunachur, karnataka, India
                  </p>
                </div>
              </div>
              <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3">
                <span>
                  <i className="bi bi-search h6"></i>
                </span>
                <div className="lh-sm border-bottom py-3 w-100">
                  <h6 className="mb-1">Railway Station back gate</h6>
                  <p className="text-muted m-0">
                    M.G. Railway Colony, Majestic, Bangaluru
                  </p>
                </div>
              </div>
              <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3">
                <span>
                  <i className="bi bi-search h6"></i>
                </span>
                <div className="lh-sm pt-3 w-100">
                  <h6 className="mb-1">Bangalore Cant</h6>
                  <p className="text-muted m-0">
                    Cantonent Railway Station Road, Contonment Railway
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*
  <!-- Sort by --> */}
      {/*
  <!-- Modal --> */}
      <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
        aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content border-0 rounded-4">
            <div className="modal-header">
              <h5 className="modal-title">Sort by</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="form-check d-flex justify-content-between mb-3 px-0 fs-6">
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Relevance
                </label>
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" //
                  checked />
              </div>
              <div className="form-check d-flex justify-content-between mb-3 px-0 fs-6">
                <label className="form-check-label text-muted" htmlFor="flexRadioDefault2">
                  Rating: High To Low
                </label>
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
              </div>
              <div className="form-check d-flex justify-content-between mb-3 px-0 fs-6">
                <label className="form-check-label text-muted" htmlFor="flexRadioDefault3">
                  Cost: Low To High
                </label>
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
              </div>
              <div className="form-check d-flex justify-content-between mb-0 px-0 fs-6">
                <label className="form-check-label text-muted" htmlFor="flexRadioDefault4">
                  Cost: High To Low
                </label>
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-white text-danger" data-bs-dismiss="modal">
                Clear All
              </button>
              <button type="button" className="btn btn-danger rounded-md">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}